<template>
  <v-form v-model="valid" :disabled="loading">
    <v-container>
      <v-row>
        <v-spacer/>
        <v-col cols="12" sm="11" md="10" lg="8" xl="6">
          <h1 class="mb-3">Your Application</h1>

          <v-alert v-if="!loggedIn" color="warning" dismissible>
            <strong>Note: </strong>
            <span>to be able to edit your application later on, you need to be </span>
            <router-link class="white--text" :to="{ name: 'login', query: { redirect: this.$route.fullPath } }">logged&nbsp;in</router-link>
            <span>.</span>
          </v-alert>

          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1 && step1valid" step="1">
                Basics
              </v-stepper-step>

              <v-divider/>

              <v-stepper-step :complete="step > 2 && step2valid" step="2">
                Application
              </v-stepper-step>

              <v-divider />

              <v-stepper-step :complete="step > 3 && step3valid" step="3">
                Review
              </v-stepper-step>

              <v-divider />

              <v-stepper-step :complete="step > 4 && submitted" step="4">
                Submit
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-container class="pa-0">
                  <v-row>
                    <v-col>
                      <v-text-field autofocus
                                    label="Name"
                                    v-model="form.name"
                                    :rules="rules.name"
                                    required
                                    min="2" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field label="E-Mail"
                                    v-model="form.email"
                                    :rules="rules.email"
                                    type="email"
                                    required />
                    </v-col>
                    <v-col>
                      <v-text-field label="Website (optional)"
                                    v-model="form.website"
                                    :rules="rules.website" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-right">
                      <v-btn :disabled="!step1valid" @click="step++">Continue</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-container class="pa-0">
                  <v-row>
                    <v-col>
                      <v-select :loading="positions.length === 0"
                                label="Position"
                                :items="positions" item-text="name" item-value="id"
                                v-model="form.position"
                                persistent-hint
                                :hint="!position || position.open ? null : 'This position is currently not open. You can still apply to it though.'"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-file-input label="Attached File (optional)"
                                    hint="Use this for your CV or an example of your work"
                                    persistent-hint
                                    v-model="form.attached_file" />
                    </v-col>
                  </v-row>
                  <v-row v-if="original_file">
                    <v-col class="d-flex">
                      <v-text-field disabled
                                    prepend-icon="attach_file"
                                    label="Currently Attached File"
                                    class="grow"
                                    v-model="original_file.name"
                      ></v-text-field>
                      <v-btn class="align-self-center" target="_blank" @click="clearFile" icon>
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>
                      <v-btn class="align-self-center" target="_blank"
                             :href="$api.content.qualify(original_file.url).href" icon>
                        <v-icon>
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea v-model="form.content"
                                  label="Application"
                                  hint="Markdown is supported"
                                  persistent-hint
                                  counter="1000"
                                  :rules="rules.content"
                                  outlined height="500"
                                  no-resize
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-right">
                      <v-btn text @click="step--" class="mr-3">Back</v-btn>
                      <v-btn :disabled="!step2valid" @click="step++">Continue</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-container class="pa-0">
                  <v-row>
                    <v-col>
                      <v-simple-table dense>
                        <tbody>
                        <tr>
                          <th scope="row">Name</th>
                          <td v-if="form.name">{{ form.name }}</td>
                          <td v-else><span class="red--text">missing</span></td>
                        </tr>
                        <tr>
                          <th scope="row">E-Mail</th>
                          <td v-if="form.email">{{ form.email }}</td>
                          <td v-else><span class="red--text">missing</span></td>
                        </tr>
                        <tr v-if="form.website">
                          <th scope="row">Website</th>
                          <td><a :href="form.website" target="_blank">{{ form.website }}</a></td>
                        </tr>
                        <tr>
                          <th scope="row">Position</th>
                          <td v-if="position">{{ position.name }}</td>
                          <td v-else><span class="red--text">missing</span></td>
                        </tr>
                        <tr v-if="form.attached_file">
                          <th scope="row">Attached File</th>
                          <td>{{ form.attached_file.name }}</td>
                        </tr>
                        <tr v-else-if="original_file">
                          <th scope="row">Attached File</th>
                          <td>{{ original_file.name }}</td>
                        </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-md-preview v-if="form.content" class="py-0" :text="form.content"></v-md-preview>
                      <span v-else class="red--text">Application missing</span>
                    </v-col>
                  </v-row>
                  <v-row v-show="submitted && !changed">
                    <v-sheet color="info" elevation="5" class="pa-3 mx-auto my-5">
                      Your application was already submitted. You can change and then submit it again.
                    </v-sheet>
                  </v-row>
                  <v-row>
                    <v-col class="text-right">
                      <v-btn text @click="step--" class="mr-3">Back</v-btn>
                      <v-btn :disabled="!changed" @click="step++" color="cta">Looks good!</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-container class="pa-0">
                  <v-row>
                    <v-col>
                      <v-sheet color="success" elevation="5" class="pa-3" v-show="!submitted">
                        You are done! Accept the privacy policy and click on "submit" to send in your application.
                      </v-sheet>

                      <v-sheet color="warning" elevation="5" class="pa-3" v-show="submitted && changed">
                        You got some unsaved changes! You can click the "submit" button to update your application.
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox v-model="agreed_privacy_policy"
                                  :rules="rules.privacy"
                                  hint="This is required to process your application."
                                  persistent-hint>
                        <template v-slot:label>
                          <span>I agree with the Privacy Policy</span>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs}">
                          <span v-bind="attrs" v-on="on">
                            <router-link :to="{name: 'privacy'}" target="_blank">View Privacy Policy.</router-link>
                          </span>
                        </template>

                        <span>Opens in a new tab.</span>
                      </v-tooltip>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn text @click="step--" class="mr-3">Back</v-btn>
                      <v-btn @click="submit" color="cta" :disabled="!enable_submit">Submit</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>

              <v-stepper-content step="5">
                <v-container class="pa-0">
                  <v-row v-if="loading">
                    <v-col class="text-center">
                      <v-progress-circular indeterminate/>
                    </v-col>
                  </v-row>
                  <template v-else>
                    <v-row>
                      <v-col>
                        <h2>{{ result.title }}</h2>

                        <p>
                          {{ result.message }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-right">
                        <v-btn v-if="result.success && loggedIn" class="mr-3" text @click="step = 3">Edit</v-btn>
                        <v-btn :to="{ name: 'home' }" class="mr-3">Home</v-btn>
                        <v-btn v-if="!result.success" color="cta" @click="submit">Try Again</v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
        <v-spacer/>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";
import api from "@/api";
import rules from "@/helpers/form-validation-rules.js";

export default {
  async created() {
    this.positions = await api.content.applications.positions();

    if (this.positions.length === 0 && (!this.loggedIn || !this.existing)) {
      this.result.title = "No open positions";
      this.result.message = "Thank you for your interest in joining us! Unfortunately, there are currently no open positions for you to apply to. Please come back regularly to check if we are looking for someone like you!";
      this.result.success = true;

      return;
    }

    if (!this.loggedIn) {
      this.loading = false;
      this.step = 1;

      return;
    }

    try {
      let existing_application = await api.content.applications.mine();

      this.form.name = existing_application.data.attributes.name;
      this.form.email = existing_application.data.attributes.email;
      this.form.content = existing_application.data.attributes.content;
      this.form.position = existing_application.data.attributes.position.data.id; // TODO: handle position being hidden
      this.form.website = existing_application.data.attributes.website;
      this.form.country = existing_application.data.attributes.country;

      this.original_file = existing_application.data.attributes.attached_file.data?.attributes;
      this.original_file_id = existing_application.data.attributes.attached_file.data?.id;

      this.step = 3;
      this.submitted = true;
      this.existing = true;
    } catch (e) {
      console.log(e);

      // use the users account email as fallback default
      this.form.email = this.user.email;

      this.step = 1;
    } finally {
      this.loading = false;

      this.$nextTick(() => {
        this.changed = false;
      });
    }
  },

  data() {
    return {
      valid: false,
      loading: true,
      changed: false,
      submitted: false,
      existing: false,
      agreed_privacy_policy: false,
      step: 5,
      result: {
        success: null,
        title: null,
        message: null,
      },
      positions: [],
      form: {
        name: null,
        content: "",
        position: null,
        email: null,
        website: null,
        country: null,
        attached_file: null,
      },
      original_file: null,
      original_file_id: null,
      rules: {
        name: [
          rules.required(),
          rules.min(2),
        ],
        email: [
          rules.required(),
          rules.email(),
        ],
        content: [
          rules.required(),
          rules.max(1000),
          rules.min(20),
        ],
        privacy: [
          rules.required('You must agree, so we can process your application.')
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      loggedIn: 'auth/isLoggedIn',
      user: 'auth/user'
    }),

    position() {
      return this.positions.find(p => p.id === this.form.position);
    },

    step1valid() {
      return rules.min(2)(this.form.name) === true && rules.email()(this.form.email) === true;
    },

    step2valid() {
      return rules.required()(this.form.position) === true && rules.min(20)(this.form.content.trim()) === true;
    },

    step3valid() {
      return this.changed || (!this.changed && this.submitted);
    },

    enable_submit() {
      return this.valid && (this.changed || !this.submitted);
    }
  },

  watch: {
    form: {
      handler() {
        this.changed = true;
      },
      deep: true
    }
  },

  methods: {
    async submit() {
      if (!this.valid)
        return;

      this.loading = true;
      this.step = 5;

      try {
        const form = {
          name: this.form.name,
          content: this.form.content,
          position: this.form.position,
          email: this.form.email,
          website: this.form.website,
          country: this.form.country,
          attached_file: this.form.attached_file || this.original_file_id
        };

        let updated_application;
        if (this.loggedIn && this.existing)
          updated_application = await api.content.applications.updateMine(form);
        else
          updated_application = await api.content.applications.create(form);

        this.form.name = updated_application.data.attributes.name;
        this.form.email = updated_application.data.attributes.email;
        this.form.content = updated_application.data.attributes.content;
        this.form.position = updated_application.data.attributes.position.data.id;
        this.form.website = updated_application.data.attributes.website;
        this.form.country = updated_application.data.attributes.country;
        this.form.attached_file = null;

        this.original_file = updated_application.data.attributes.attached_file.data?.attributes;
        this.original_file_id = updated_application.data.attributes.attached_file.data?.id;

        this.result.success = true;
        if (this.loggedIn) {
          this.result.title = "Application updated";
          this.result.message = "Your application was saved successfully. Thank you!";
        } else {
          this.result.title = "Application submitted";
          this.result.message = "Your application has been submitted. Thank you!";
        }

        this.submitted = true;
        this.changed = false;
      } catch (e) {
        this.result.success = false;
        this.result.title = "Submission failed";
        this.result.message = "Something went wrong while loading your application! Please try again later.";

        this.submitted = false;

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    beforeLeave() {
      // in case the submit button is enabled, there are not yet submitted changes
      if (!this.enable_submit)
        return undefined;

      return "Application not saved! Are you sure you want to leave?";
    },

    clearFile() {
      this.original_file = null;
      this.original_file_id = null;
      this.changed = true;
    }
  },

  beforeRouteLeave(to, from, next) {
    let question = this.beforeLeave();

    // undefined = successfully submitted or not submitted yet
    if (question === undefined)
      next();
    else {
      // ask question before leaving
      const answer = window.confirm(question);
      if (answer)
        next();
      else
        next(false); // abort leaving this page
    }
  }
}
</script>

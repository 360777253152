import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from "./auth";
import options from "./options";
import gallery from "./gallery";
import articles from "./articles";
import faqs from "./faqs";
import frontpage from "./frontpage";
import game_information from "./game-information.js";
import presskits from "./presskits.js";

export default new Vuex.Store({
  modules: {
    options,
    auth,
    gallery,
    articles,
    faqs,
    frontpage,
    game_information,
    presskits,
  }
})

import api from "@/api";

export default {
  namespaced: true,

  state: {
    loaded: false,
    components: [],
  },

  getters: {
    components(state) {
      return state.components;
    },
  },

  mutations: {
    storeComponent(state, payload) {
      state.components.push(payload);
    },

    setLoaded(state, loaded) {
      state.loaded = loaded;
    }
  },

  actions: {
    async load({ state, commit }) {
      if (state.loaded)
        return;

      try {
        let gallery = await api.content.gallery.all();
        let components = gallery.data.attributes.components;

        for (const component of components) {
          if (!['media.image', 'media.video'].includes(component.__component)) {
            continue;
          }

          commit('storeComponent', component);
        }

        commit('setLoaded', true);
      } catch (e) {
        console.error(e);
      }
    }
  }
}

<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-spacer />
      <v-col cols="12" style="max-width: 700px;">
        <v-dialog v-model="promo_video_shown" width="700">
          <template v-slot:activator="{ on, attrs }">
            <v-img src="/assets/home-video-placeholder.png"
                   height="250"
                   width="700"
                   v-bind="attrs"
                   v-on="on"
                   class="pointer"
                   contain />
          </template>

          <v-responsive :aspect-ratio="700/394">
            <iframe :src="promo_video_url"
                    id="promo-video-frame"
                    style="max-width: 700px; max-height: 394px; width: 100%; height: 100%"
                    allow="encrypted-media"
                    allowscriptaccess="always"
                    allowfullscreen></iframe>
          </v-responsive>
        </v-dialog>
      </v-col>
      <v-spacer />
    </v-row>

    <v-spacer class="spacer-50" />
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="10" md="8" class="text-center sans-pro semi-bold uppercase lh-12 fpx-18">
        <v-skeleton-loader type="paragraph" :loading="promo_text === null">
          <p>{{ promo_text }}</p>
        </v-skeleton-loader>
      </v-col>
      <v-spacer />
    </v-row>

    <v-spacer class="spacer-100" />
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="text-center">
        <h2 class="sans-pro uppercase semi-bold fpx-20 my-5">
          Latest News
        </h2>

        <v-skeleton-loader :loading="articles.length === 0" type="card">
          <v-btn @click="article_shown--" :disabled="article_shown === 0" small fab class="mb-2">
            <v-icon>
              expand_less
            </v-icon>
          </v-btn>

          <v-carousel vertical vertical-delimiters="right"
                      v-model="article_shown"
                      height="300px"
                      cycle interval="10000"
                      progress progress-color="cta"
                      class="rounded-t">
            <v-carousel-item v-for="article in articles" :key="article.id"
                             :to="{name: 'article-detail', params: { article_id: article.id } }">
              <v-img :lazy-src="$contentImage(article.cover_image, 'thumbnail')"
                     :src="$contentImage(article.cover_image, 'large', true, true)"
                     eager
                     class="fill-height" />
            </v-carousel-item>
          </v-carousel>

          <v-sheet style="min-height: 180px" class="pa-3 rounded-b text-left" v-if="articles.length > article_shown">
            <v-card-title>{{ articles[article_shown].title }}</v-card-title>
            <v-card-subtitle>{{ articles[article_shown].publishedAt | moment("LLL") }}</v-card-subtitle>
            <v-card-text>
              {{ articles[article_shown].summary }}
            </v-card-text>
          </v-sheet>

          <v-btn @click="article_shown = (article_shown + 1) % articles.length" small fab class="mt-2">
            <v-icon>
              expand_more
            </v-icon>
          </v-btn>
        </v-skeleton-loader>
      </v-col>
      <v-spacer />
    </v-row>

    <v-row>
      <v-col class="text-center">
        <v-btn color="secondary" class="zoom" small :to="{name: 'articles'}">Show all news &raquo;</v-btn>
      </v-col>
    </v-row>

    <v-spacer class="spacer-50" />
    <v-row class="text-center sans-pro bold-black uppercase fpt-18 d-none d-md-flex">
      <v-hover v-slot:default="{hover}">
        <v-col :cols="hover ? 6 : 5"
               :md="hover ? 5 : 4"
               class="ml-auto kickstarter sponsor-btn rounded-l">
          <h1>Kickstarter</h1>
          <v-btn disabled block x-large class="bold-black">Coming soon</v-btn>
        </v-col>
      </v-hover>
      <v-hover v-slot:default="{hover}">
        <v-col :cols="hover ? 6 : 5"
               :md="hover ? 5 : 4"
               class="mr-auto patreon sponsor-btn rounded-r">
          <h1>Patreon</h1>
          <v-btn block x-large class="bold-black" target="_blank" href="https://www.patreon.com/ageofaincrad">Become a Patron</v-btn>
        </v-col>
      </v-hover>
    </v-row>
    <v-row class="spacer-100 text-center sans-pro bold-black uppercase fpt-18 d-flex d-md-none">
      <v-spacer />
      <v-col class="kickstarter sponsor-btn rounded">
        <h3>Kickstarter</h3>
        <v-btn disabled block class="bold-black">Coming soon</v-btn>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row class="mt-5 text-center sans-pro bold-black uppercase fpt-18 d-flex d-md-none">
      <v-spacer />
      <v-col class="patreon sponsor-btn rounded">
        <h3>Patreon</h3>
        <v-btn block class="bold-black" target="_blank" href="https://www.patreon.com/ageofaincrad">Become a Patron</v-btn>
      </v-col>
      <v-spacer />
    </v-row>

    <v-spacer class="spacer-50" />
    <v-row>
      <v-spacer />
      <v-col cols="7" sm="6" md="5">
        <v-img src="/assets/logos/discord.png" contain min-height="150px" max-height="200px" height="35vh" />
      </v-col>
      <v-col align-self="center" cols="5" sm="4" md="3" class="text-center sans-pro bold-black uppercase">
        <p class="fpx-24">
          Come chat with us
        </p>

        <v-btn x-large href="https://discord.age-of-aincrad.com/" target="_blank" class="bold-black zoom" color="cta">
          Join now
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>

    <v-row class="join-team-banner-row">
      <v-spacer />
      <v-col cols="10" md="7" align-self="center" class="text-right sans-pro bold-black uppercase">
        <p class="fpx-36">
          Interested&nbsp;in<br>
          joining&nbsp;our&nbsp;team?
        </p>

        <v-btn x-large class="bold-black" color="cta" :to="{ name: 'apply' }">
          Apply now
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>

    <v-row>
      <v-spacer />
      <v-col cols="12" sm="10" md="8">
        <h2 class="text-center sans-pro uppercase semi-bold mt-10 mb-5 fpx-20">
          Why not check out our <span class="cta--text">concept art</span> &amp;
          <span class="cta--text">screenshots</span>?
        </h2>

        <v-skeleton-loader type="image" :loading="gallery_components.length === 0">
          <v-item-group>
            <v-container>
              <v-row>
                <v-col v-for="entry in gallery_components" :key="entry.__component + '.' + entry.id"
                       cols="12" md="4">
                  <picture v-if="entry.__component === 'media.image'" class="squared-gallery-preview">
                    <source :srcset="$contentImage(entry.image, 'thumbnail')" :width="$contentImage(entry.image, 'thumbnail', true, false, 'width')" :height="$contentImage(entry.image, 'thumbnail', true, false, 'height')">
                    <source :srcset="$contentImage(entry.image, 'small')" :width="$contentImage(entry.image, 'small', true, false, 'width')" :height="$contentImage(entry.image, 'small', true, false, 'height')">
                    <source :srcset="$contentImage(entry.image, 'medium')" :width="$contentImage(entry.image, 'medium', true, false, 'width')" :height="$contentImage(entry.image, 'medium', true, false, 'height')">
                    <img :src="$contentImage(entry.image)" :alt="entry.title" />
                  </picture>
                  <video v-else-if="entry.__component === 'media.video'" class="gallery-video" autoplay loop muted controls :title="entry.title">
                    <source :src="$api.content.qualify(entry.video.data.attributes.url)" :type="entry.video.data.attributes.mime">
                  </video>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-skeleton-loader>
      </v-col>
      <v-spacer />
    </v-row>

    <v-row class="spacer-100">
      <v-spacer />
      <v-col cols="12" sm="10" md="8" class="text-center sans-pro fpx-18">
        <h2 class="sans-pro uppercase semi-bold fpx-20">
          Questions?
        </h2>

        <p class="mt-5">Check out our FAQ, which will (hopefully) answer all of your questions.</p>

        <v-btn :to="{ name: 'faq' }" class="bold-black zoom" large color="cta">FAQ</v-btn>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import Lightbox from "@/components/Lightbox.vue";
import {mapGetters} from "vuex";

export default {
  components: {Lightbox},

  async created() {
    await this.$store.dispatch('frontpage/load');
    await this.$store.dispatch('articles/load');
    await this.$store.dispatch('gallery/load');
  },

  data() {
    return {
      promo_video_shown: false,
      article_shown: 0,
    };
  },

  computed: {
    ...mapGetters({
      gallery_components: 'gallery/components',
      articles: 'articles/latest',
      promo_text: 'frontpage/promotional_text',
      promo_video_embed_url: 'frontpage/promotional_video_embed_url'
    }),

    show_kspat() {
      return process.env.VUE_APP_ENABLE_KS_PAT === 'true';
    },

    promo_video_url() {
      if (this.promo_video_embed_url == null)
        return null;

      let url = new URL(this.promo_video_embed_url);

      // why these params? see: https://stackoverflow.com/a/30358006/5107884
      url.searchParams.append('enablejsapi', '1');
      url.searchParams.append('version', '3');
      url.searchParams.append('playerapiid', 'ytplayer');

      return url.href;
    }
  },

  methods: {
    pausePromoVideo() {
      let frame = document.getElementById('promo-video-frame');
      frame.contentWindow.postMessage(
        JSON.stringify({
          event: "command",
          func: "pauseVideo",
          args: ""
        }),
        '*'
      );
    }
  },

  watch: {
    promo_video_shown() {
      if (!this.promo_video_shown)
        this.pausePromoVideo();
    }
  }
};
</script>

<style lang="scss" scoped>
.join-team-banner-row {
  min-height: 241px;
  max-height: 341px;
  height: 30vh;
  background-image: url('/assets/render.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
}

.sponsor-btn {
  transition: all 0.4s cubic-bezier(0.75, -0.5, 0, 1.75);
  transform: skewX(-10deg);
}

.squared-gallery-preview {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.gallery-video {
  width: 100%;
  height: auto;
  object-fit: contain;
}
</style>

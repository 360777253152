<template>
  <div>
    <v-app-bar app clipped-left dense color="aoa">
      <v-container class="py-0 fill-height">
        <v-app-bar-nav-icon
          class="d-md-none"
          @click.stop="navigation_drawer_shown = !navigation_drawer_shown">
        </v-app-bar-nav-icon>

        <v-toolbar-title class="px-1 d-none d-md-inline-flex">
          <router-link :to="{ name: 'home' }">
            <v-img eager contain height="36" width="36" alt="Age of Aincrad" src="/assets/_logo.png"/>
          </router-link>
        </v-toolbar-title>

        <template v-for="(item, tli) in nav">
          <v-btn :key="tli"
                 v-if="!item.hasOwnProperty('children') && item.hasOwnProperty('to')"
                 :to="{ name: item.to }" exact
                 class="d-none d-md-inline-flex px-2"
                 text>
            {{ item.label }}
          </v-btn>

          <v-btn :key="tli"
                 v-if="!item.hasOwnProperty('children') && item.hasOwnProperty('href')"
                 :href="item.href"
                 :target="item.target"
                 class="d-none d-md-inline-flex px-2"
                 text>
            {{ item.label }}
          </v-btn>

          <v-menu :key="tli"
                  v-if="item.hasOwnProperty('children')"
                  open-on-hover
                  bottom offset-y>
            <template v-slot:activator="{on, attrs}">
              <v-btn v-if="item.hasOwnProperty('to')"
                     text
                     v-bind="attrs"
                     v-on="on"
                     class="d-none d-md-inline-flex px-2"
                     :to="{ name: item.to }" exact>
                {{ item.label }}
              </v-btn>
              <v-btn v-else
                     text
                     v-bind="attrs"
                     v-on="on"
                     class="d-none d-md-inline-flex px-2">
                {{ item.label }}
              </v-btn>
            </template>

            <v-list dense
                    color="aoa"
                    elevation="6">
              <template v-for="(child, sli) in item.children">
                <v-list-item v-if="child.hasOwnProperty('to')"
                             :key="sli"
                             :to="{ name: child.to }">
                  <v-list-item-icon v-if=" child.hasOwnProperty('icon') && child.hasOwnProperty('force_icon') && child.force_icon">
                    <v-icon>
                      {{ child.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ child.label }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-else-if="child.hasOwnProperty('href')"
                             :key="sli"
                             :href="child.href"
                             :target="child.target">
                  <v-list-item-icon v-if=" child.hasOwnProperty('icon') && child.hasOwnProperty('force_icon') && child.force_icon">
                    <v-icon>
                      {{ child.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ child.label }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </template>

        <v-spacer/>

        <v-btn icon link active-class="no-active" :to="{name: 'search'}" class="d-none d-md-inline-flex">
          <v-icon>
            search
          </v-icon>
        </v-btn>

        <template v-if="loggedIn === false">
          <v-dialog v-if="$route.name !== 'login'" v-model="login_dialog_shown" max-width="350">
            <template v-slot:activator="{ on, attrs}">
              <v-btn text v-on="on" v-bind="attrs">Login</v-btn>
            </template>

            <login-form>
              <template v-slot:pre-body>
                <v-btn style="position: absolute; right: 0" @click="login_dialog_shown = false" icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
            </login-form>
          </v-dialog>
        </template>
        <v-btn v-else-if="loggedIn === true" text :to="{ name: 'account' }">{{ user.username }}</v-btn>
        <v-progress-circular v-else indeterminate/>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer v-model="navigation_drawer_shown" app temporary>
      <v-list nav>
        <v-list-item link exact active-class="no-active" :to="{name: 'home'}">
          <v-list-item-content>
            <v-list-item-title class="title">
              Age of Aincrad
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <template v-for="(item, tli) in nav">
          <v-list-group v-if="item.hasOwnProperty('children')"
                        :key="tli"
                        :prepend-icon="item.icon">
            <template v-slot:activator>
              <v-list-item-title>
                {{ item.label }}
              </v-list-item-title>
            </template>

            <v-list-item v-for="(child, sli) in item.children" :key="sli">
              <v-list-item v-if="child.hasOwnProperty('to')"
                           :key="sli"
                           link
                           :to="{ name: child.to }" exact>
                <v-list-item-icon v-if="child.hasOwnProperty('icon')">
                  <v-icon>
                    {{ child.icon }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ child.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else-if="child.hasOwnProperty('href')"
                           :key="sli"
                           link
                           :href="child.href">
                <v-list-item-icon v-if="child.hasOwnProperty('icon')">
                  <v-icon>
                    {{ child.icon }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ child.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else-if="item.hasOwnProperty('to')"
                       :key="tli"
                       link
                       :to="{ name: item.to }" exact>
            <v-list-item-icon v-if="item.hasOwnProperty('icon')">
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-else-if="item.hasOwnProperty('href')"
                       :key="tli"
                       link
                       :href="item.href">
            <v-list-item-icon v-if="item.hasOwnProperty('icon')">
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item :to="{name:'search'}">
          <v-list-item-icon>
            <v-icon>
              search
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Search
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LoginForm from "@/components/auth/LoginForm.vue";

export default {
  name: "navbar",

  components: {LoginForm},

  props: {
    nav: {
      type: Array
    }
  },

  data() {
    return {
      navigation_drawer_shown: false,
      login_dialog_shown: false,
    }
  },

  computed: {
    ...mapGetters({
      loggedIn: 'auth/isLoggedIn',
      user: 'auth/user',
    }),
  },
}
</script>

<style scoped>
.no-active::before {
  opacity: 0 !important;
}
</style>

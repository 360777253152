import api from "@/api";

export default {
  namespaced: true,

  state: {
    info_text: null,
  },

  getters: {
    info_text(state) {
      return state.info_text;
    }
  },

  mutations: {
    storeInfoText(state, payload) {
      state.info_text = payload;
    },

    setLoaded(state, loaded) {
      state.loaded = loaded;
    }
  },

  actions: {
    async load({ state, commit }) {
      if (state.loaded)
        return;

      try {
        let metadata = await api.content.game_information();

        commit('storeInfoText', metadata.content);
        commit('setLoaded', true);
      } catch (e) {
        console.error(e);
      }
    }
  }
}

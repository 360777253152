import ApiClient from "@/helpers/api-client.js";
import api from "@/api";

export default {
  async all() {
    let url = api.content.qualify('presskits');
    let response = await ApiClient.getJson(url.href);

    return response.data;
  },

  async count() {
    let url = api.content.qualify('presskits');
    let response = await ApiClient.getJson(url.href);

    return response.meta.pagination.total;
  }
}

<template>
  <v-footer class="text--disabled sans-pro uppercase fpx-14">
    <v-bottom-sheet hide-overlay inset
                    persistent
                    v-model="sheet_shown">
      <v-sheet class="pa-2">
        <privacy-popup-content />
      </v-sheet>
    </v-bottom-sheet>

    <v-container>
      <v-row>
        <v-col>
          <ul>
            <li>&copy; {{ copyright_year }} age-of-aincrad.com</li>
            <li>All rights reserved</li>
            <li>
              <router-link :to="{ name: 'contact' }">Contact us</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'privacy' }">Privacy policy</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'terms' }">Terms of service</router-link>
            </li>
            <li>
              <button type="button" class="btn-link" @click="resetRespondedTos">Change privacy and ToS settings</button>
            </li>
          </ul>
        </v-col>
        <v-col class="text-right">
          <ul>
            <li>Designed by Zenro45</li>
            <li>Developed by Ashley Scott</li>
            <li>and <a href="https://ricardoboss.de" target="_blank" class="link-transparent">Ricardo Boss</a></li>
            <li class="text-none">{{ git_hash_short }}</li>
            <li>
              <a target="_blank" href="https://github.com/cardinal-games/age-of-aincrad.com-feedback/discussions">Feedback?</a>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import PrivacyPopupContent from "@/components/PrivacyPopupContent.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'AppFooter',

  components: {PrivacyPopupContent},

  mounted() {
    this.updateAnalyticsCode();
  },

  computed: {
    ...mapGetters({
      acceptedTos: 'options/acceptedTos',
      respondedTos: 'options/respondedTos'
    }),

    git_hash_short() {
      return process.env.VUE_APP_GIT_HASH;
    },

    copyright_year() {
      return new Date().getFullYear();
    },

    sheet_shown() {
      if (!this.acceptedTos)
        this.resetRespondedTos();

      return !this.respondedTos && !['contact', 'privacy', 'terms'].includes(this.$route.name);
    }
  },

  watch: {
    acceptedTos() {
      this.updateAnalyticsCode();
    },
  },

  methods: {
    ...mapActions({
      resetRespondedTos: 'options/resetRespondedTos'
    }),

    updateAnalyticsCode() {
      const insertedCodeId = 'matomo-script';

      if (!this.acceptedTos) {
        let inserted = document.querySelector('#' + insertedCodeId);
        if (inserted === null)
          return;

        // MAYBE: reload instead of removing script, since it may have already been loaded

        inserted.parentNode.removeChild(inserted);

        return;
      }

      let _paq = window._paq = window._paq || [];
      const base_url = "https://matomo.age-of-aincrad.com/";

      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['setTrackerUrl', base_url + 'matomo.php']);
      _paq.push(['setSiteId', '1']);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);

      let element = document.createElement('script'),
        firstScript = document.getElementsByTagName('script')[0];

      element.id = insertedCodeId;
      element.type = 'text/javascript';
      element.async = true;
      element.defer = true;
      element.src = base_url + 'matomo.js';

      firstScript.parentNode.insertBefore(element, firstScript);
    }
  },
}
</script>

<style scoped lang="scss">
ul {
  list-style: none;

  a, .btn-link {
    text-transform: none;
  }
}

.btn-link {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--v-anchor-base);
  text-decoration: underline;
  text-align: inherit;
  outline: none;
}

.link-transparent {
  color: inherit;
  text-decoration: inherit;
  text-transform: inherit;
}
</style>

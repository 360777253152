<template>
  <v-container>
    <v-row>
      <v-col sm="10" md="8" lg="7" class="mx-auto">
        <v-sheet elevation="5" class="px-4 py-2">
          <div class="d-flex flex-row">
            <v-text-field
              v-model="input"
              clearable
              :loading="loading"
              label="Search"
              class="flex-grow-1"
              hint="Min. 3 characters required"
              autofocus
              :error="!!input && input.length > 0 && input.length < 3"/>
            <v-btn @click="loadSearch" icon class="ml-1 mt-4 flex-grow-0">
              <v-icon>
                search
              </v-icon>
            </v-btn>
          </div>
          <v-chip-group multiple
                        active-class="primary--text"
                        v-model="selected_categories">
            <v-chip v-for="category in categories" :key="category.key">
              {{ category.label }}
            </v-chip>
          </v-chip-group>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="10" lg="8" class="mx-auto" id="results_container">
        <ul v-show="results.length" id="results">
          <li v-for="(item, i) in results" :key="i" class="pa-4 mb-3">
            <div class="d-flex flex-row align-center">
              <router-link :to="item.link">
                <h3 v-html="item.title"></h3>
              </router-link>
              <v-chip
                label
                style="height: 50%; overflow: initial"
                class="ml-3"
                color="secondary"
              >{{ getTypeLabel(item.type) }}</v-chip>
            </div>
            <p v-html="item.description"></p>
          </li>
        </ul>
        <p v-show="!results.length && !input" class="text-center pa-4">
          Select the categories you want to search in and add a keyword to the search box above.
        </p>
        <p v-show="!results.length && (!!input && input.length > 0)" class="text-center pa-4">
          No results found for your query.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/api";
import RESULT_TYPE from "@/api/content/search";

export default {
  created() {
    // check if a query parameter was given as the query
    if (this.$route.query.hasOwnProperty('q') && this.$route.query.q.length > 0)
      this.input = this.$route.query.q;
  },

  mounted() {
    // mark all categories as selected
    this.selected_categories.push(...this.categories.map((v, i) => i))
  },

  data() {
    return {
      loading: false,
      search_model: null,
      input: null,
      results: [],
      cool_down_active: false,

      // don't change the index of each entry
      categories: [
        {label: 'Articles', key: RESULT_TYPE.ARTICLE},
        {label: 'Presskits', key: RESULT_TYPE.PRESSKIT},
        {label: 'FAQs', key: RESULT_TYPE.FAQ},
      ],
      selected_categories: []
    }
  },

  watch: {
    async input() {
      if (!this.input)
        return;

      let search_value = JSON.parse(JSON.stringify(this.input));

      // small delay before starting search
      await new Promise(r => setTimeout(r, 200));

      let current_value = JSON.parse(JSON.stringify(this.input));
      if (current_value.length < 3 || search_value !== current_value)
        return;

      await this.loadSearch();
    },

    async selected_categories() {
      await this.loadSearch();
    },
  },

  methods: {
    getTypeLabel(type) {
      switch (type) {
        case 'article':
          return "Article";
        case 'presskit':
          return "Presskit";
        case 'faq':
          return "FAQ";
        default:
          return "unknown";
      }
    },

    async loadSearch(depth = 0) {
      if (this.loading && depth === 0)
        return;

      if (
        !this.input || this.input.length < 3 ||
        !this.selected_categories || this.selected_categories.length === 0
      ) {
        this.results = [];

        return;
      }

      try {
        this.loading = true;

        if (this.cool_down_active)
          await new Promise(r => setTimeout(r, 10000));

        if (
          !this.input || this.input.length < 3 ||
          !this.selected_categories || this.selected_categories.length === 0
        )
          return;

        this.results = [];

        if (this.selected_categories.includes(0)) {
          let articles = await api.content.search.articles(this.input);
          this.results.push(...articles);
        }

        if (this.selected_categories.includes(1)) {
          let presskits = await api.content.search.presskits(this.input);
          this.results.push(...presskits);
        }

        if (this.selected_categories.includes(2)) {
          let faqs = await api.content.search.faqs(this.input);
          this.results.push(...faqs);
        }

        let markedInput = `<mark style="background-color: var(--v-cta-base); color: #fff; font-weight: normal;">${this.input}</mark>`;
        let re = new RegExp(`\w?${this.input}\w?`, 'gi')

        this.results.forEach(result => {
          result.description = result.description.replace(re, markedInput);
          result.title = result.title.replace(re, markedInput)
        });

        this.cool_down_active = false;

        this.$route.query.q = this.input;
      } catch (e) {
        console.error(e);

        this.cool_down_active = true;
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
#results_container {
  min-height: 50vh;
}

#results {
  list-style: none;
  padding: 0;
}
</style>

import ApiClient from "@/helpers/api-client.js";

import search from "./search.js";

// endpoints
import applications from "./applications";
import articles from "./articles";
import auth from "./auth";
import gallery from "./gallery";
import users from "./users";
import faqs from "./faqs";
import enquiries from "./enquiries";
import presskits from "./presskits";

// base url for content API
const base_url = process.env.VUE_APP_CONTENT_API;

export default {
  qualify(url) {
    return new URL(url, base_url);
  },

  async frontpage() {
    return (await ApiClient.getJson(this.qualify('frontpage').href)).data.attributes;
  },

  async game_information() {
    return (await ApiClient.getJson(this.qualify('game-information').href)).data.attributes;
  },

  search,

  applications,
  gallery,
  articles,
  auth,
  users,
  enquiries,
  faqs,
  presskits
}

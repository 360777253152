<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="9" md="7">
        <h1 class="text-center">Presskits</h1>

        <p>
          These are all presskits available:
        </p>

        <v-skeleton-loader type="paragraph@3,article@3" :loading="kits.length === 0">
          <ul>
            <li v-for="kit in kits" :key="kit.id">
              <a :href="'#' + kit.name.toLowerCase()" @click.prevent="$vuetify.goTo('#' + kit.name.toLowerCase())">{{ kit.name }}</a>
            </li>
          </ul>

          <v-divider class="my-5" />

          <v-container fluid>
            <template v-for="(kit, i) in kits">
              <v-row :key="kit.id">
                <v-col>
                  <h2 :id="kit.name.toLowerCase()">{{ kit.name }}</h2>

                  <v-md-preview v-if="kit.description" v-text="kit.description"></v-md-preview>

                  <v-carousel v-if="kit.media" height="250">
                    <v-carousel-item v-for="entry in kit.media" :key="entry.id">
                      <v-img v-if="entry.mime.indexOf('image/') === 0"
                             :lazy-src="$contentImage(entry, 'small').href"
                             :src="$contentImage(entry, 'large').href"
                             contain></v-img>
                    </v-carousel-item>
                  </v-carousel>

                  <v-btn v-if="kit.download !== null"
                         class="mt-3 float-right"
                         download
                         target="_blank"
                         :href="$contentImage(kit.download).href">Download</v-btn>
                </v-col>
              </v-row>

              <v-divider class="my-5" v-if="i < kits.length - 1" />
            </template>
          </v-container>
        </v-skeleton-loader>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  async created() {
    await this.$store.dispatch('presskits/loadAll')
  },

  computed: {
    ...mapGetters({
      kits: 'presskits/all'
    })
  }
}
</script>

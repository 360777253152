<template>
  <v-form :disabled="submitting" v-model="valid">
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="10" md="8" lg="6">
          <v-sheet elevation="5" class="pa-4">
            <v-row>
              <v-col class="text-center">
                <h1>Contact</h1>

                <p>
                  Use this form if you have legal inquiries or questions, which cannot be discussed in the forums or in Discord.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Name" autofocus v-model="form.name" :rules="rules.name" required />
              </v-col>
              <v-col>
                <v-text-field label="E-Mail" type="email" v-model="form.email" :rules="rules.email" required />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select label="Type" :items="types" v-model="form.type" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea label="Message" v-model="form.message" :rules="rules.message" counter="1000" min="10" max="1000" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <vue-recaptcha sitekey="6LfqlNgZAAAAANdoscYCeZ1bq70UZ2savnPQgthV"
                               type="homepage" theme="dark" class="d-inline-block"
                               :load-recaptcha-script="true"
                               @verify="recaptachaVerified"
                               @expired="recaptachaExpired"
                               @error="recaptachaError"></vue-recaptcha>
                <div v-show="valid && !recaptcha" class="red--text">
                  <small>Please confirm you are not a robot.</small>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-progress-circular v-show="submitting" class="mr-3" indeterminate/>
                <v-btn type="submit" :disabled="!validAndCaptcha || submitting" color="primary" @click.prevent="submit">Submit</v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-spacer/>
      </v-row>
    </v-container>

    <v-dialog v-model="result.shown" max-width="300">
      <v-card>
        <v-card-title>{{ result.title }}</v-card-title>
        <v-card-text>{{ result.message }}</v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn type="submit" v-if="!result.success" text @click.prevent="submit" color="cta">Try Again</v-btn>
          <v-btn text @click="result.shown = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import api from '@/api';
import rules from '@/helpers/form-validation-rules.js';
import {mapGetters} from "vuex";

export default {
  name: "contact",

  components: { VueRecaptcha },

  created() {
    if (!this.user)
      return;

    this.form.email = this.user.email;
  },

  data() {
    return {
      valid: false,
      submitting: false,
      recaptcha: null,
      types: ['Question', 'Legal', 'Message', 'Other'],
      form: {
        name: null,
        email: null,
        phone: null,
        type: 'Question',
        message: null
      },
      rules: {
        name: [
          rules.required(),
          rules.min(2),
        ],
        email: [
          rules.required(),
          rules.email()
        ],
        message: [
          rules.required(),
          rules.min(10),
          rules.max(1000)
        ]
      },
      result: {
        shown: false,
        title: null,
        message: null,
        success: false,
        redirectAfterClose: false,
      }
    }
  },

  methods: {
    async submit() {
      this.result.success = false;
      this.result.shown = false;
      this.result.redirectAfterClose = false;
      this.submitting = true;

      try {
        let enquiry = {
          email: this.form.email,
          message: this.form.message,
          name: this.form.name,
          phone: this.form.phone,
          type: this.form.type.toLowerCase()
        };

        if (this.user)
          enquiry.user = this.user.id;

        await api.content.enquiries.create(enquiry);

        this.result.success = true;
        this.result.title = "Submission successful";
        this.result.message = "Your enquiry was sent successfully. Thanks for contacting us!";
        this.result.redirectAfterClose = true;
      } catch (e) {
        this.submitting = false;

        this.result.title = "Submission failed";
        this.result.message = "Unfortunately, your enquiry couldn't be sent. You can still try to contact us via e-mail at: info@age-of-aincrad.com";
      } finally {
        this.result.shown = true;
      }
    },

    recaptachaVerified() {
      this.recaptcha = true;
    },

    recaptachaExpired() {
      this.recaptcha = null;
    },

    recaptachaError() {
      this.recaptcha = false;

      // MAYBE: reload page? inform user?
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    validAndCaptcha() {
      return this.valid && this.recaptcha === true;
    },

    resultShown() {
      return this.result.shown;
    }
  },

  watch: {
    async resultShown() {
      if (this.result.shown || !this.result.redirectAfterClose)
        return;

      await this.$router.push({ name: 'home', query: { ref: 'contact' } });
    }
  }
}
</script>

export default class ApiClient {
  static auth_token = null;

  static setAuthToken(token) {
    console.log(`[ApiClient] Got auth token`)

    this.auth_token = token;
  }

  static resetAuthToken() {
    console.log(`[ApiClient] Reset auth token`)

    this.auth_token = null;
  }

  static buildHeaders() {
    let h = {};

    if (this.auth_token !== null)
      h.Authorization = `Bearer ${this.auth_token}`;

    return h;
  }

  static convertToFormData(data) {
    let form = new FormData();
    let form_data = {};

    for (let key in data) {
      if (!data.hasOwnProperty(key))
        continue;

      if (data[key] instanceof File)
        form.append('files.' + key, data[key]);
      else
        form_data[key] = data[key];
    }

    form.append('data', JSON.stringify(form_data));

    return form;
  }

  static async get(url) {
    console.log(`[ApiClient] [GET] ${url}`)

    return await fetch(
      url,
      {
        headers: this.buildHeaders()
      });
  }

  static async post(url, body) {
    console.log(`[ApiClient] [POST] ${url}`)

    return await fetch(
      url,
      {
        method: 'POST',
        body: this.convertToFormData(body),
        headers: {
          ...this.buildHeaders()
        }
      });
  }

  static async put(url, body) {
    console.log(`[ApiClient] [PUT] ${url}`)

    return await fetch(
      url,
      {
        method: 'PUT',
        body: this.convertToFormData(body),
        headers: {
          ...this.buildHeaders()
        }
      });
  }

  static async getJson(url) {
    let r = await this.get(url);
    if (r.status > 299)
      throw new Error("Invalid response status: " + r.status);

    try {
      return await r.json();
    } catch (e) {
      throw new Error("Failed to parse result as JSON");
    }
  }

  static async postJson(url, body) {
    let r = await this.post(url, body);
    if (r.status > 299)
      throw new Error("Invalid response status: " + r.status);

    try {
      return await r.json();
    } catch (e) {
      throw new Error("Failed to parse result as JSON");
    }
  }

  static async putJson(url, body) {
    let r = await this.put(url, body);
    if (r.status > 299)
      throw new Error("Invalid response status: " + r.status);

    try {
      return await r.json();
    } catch (e) {
      throw new Error("Failed to parse result as JSON");
    }
  }
}

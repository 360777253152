export const BG_TYPE = {
  image: 'image',
  video: 'video',
  webm: 'webm',
  none: 'none'
};

const accepted_tos_key = "accepted_tos";

export default {
  namespaced: true,

  state: {
    background_url: null,
    background_type: BG_TYPE.none,

    accepted_tos: null,
    responded_tos: false,
  },

  getters: {
    background_url(state) {
      return state.background_url;
    },

    isImage(state) {
      return state.background_type === BG_TYPE.image;
    },

    isVideo(state) {
      return state.background_type === BG_TYPE.video;
    },

    isWebm(state) {
      return state.background_type === BG_TYPE.webm;
    },

    isNone(state) {
      return state.background_type === BG_TYPE.none;
    },

    acceptedTos(state) {
      // check if we already fetched the value
      if (state.accepted_tos === null) {
        // fetch from local storage
        let accepted = localStorage.getItem(accepted_tos_key);

        // check if value is set
        if (accepted === null) {
          // value was not set, default to false
          // noinspection JSValidateTypes
          state.accepted_tos = false;
        } else {
          // noinspection JSValidateTypes
          state.accepted_tos = (accepted === "true");

          // already responded since value was read from local storage
          state.responded_tos = true;
        }
      }

      return state.accepted_tos;
    },

    respondedTos(state) {
      return state.responded_tos;
    }
  },

  mutations: {
    updateBackground(state, {url, type}) {
      state.background_url = url;
      state.background_type = type;
    },

    setAcceptedTos(state, {accept}) {
      localStorage.setItem(accepted_tos_key, accept);

      state.accepted_tos = accept;
    },

    setRespondedTos(state) {
      state.responded_tos = true;
    },

    resetRespondedTos(state) {
      state.responded_tos = false;
    }
  },

  actions: {
    updateBackground({commit}, {url, type}) {
      commit('updateBackground', {url, type})
    },

    resetBackground({commit}) {
      commit('updateBackground', {url: null, type: BG_TYPE.none});
    },

    setAcceptedTos({commit}, {accept}) {
      commit('setAcceptedTos', {accept});

      commit('setRespondedTos');
    },

    resetRespondedTos({commit}) {
      commit('resetRespondedTos')
    }
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import goTo from 'vuetify/es5/services/goto'

Vue.use(VueRouter)

// import routes after use in Vue
import routes from './routes';

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.meta.hasOwnProperty('noScroll') && to.meta.noScroll === true)
      return null;

    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  let authRequired = to.meta.authRequired;
  let authenticated = store.getters["auth/isLoggedIn"];

  // authRequired: true = only authenticated access, false = only guest access, null = everyone allowed
  // authenticated: true = user is authenticated, false = not authenticated, null = currently logging in/unknown

  // try to refresh the users session before navigating
  if (authenticated === false) {
    await store.dispatch('auth/tryRefreshUser');

    authenticated = store.getters["auth/isLoggedIn"];
  }

  if (authRequired === null || authRequired === authenticated) {
    next(); // access granted
  } else {
    if (authenticated === false) { // auth required, but not authenticated
      next({name: 'login', query: { ref: 'guard_login', redirect: to.fullPath }});
    } else { // guest required, but authenticated or currently logging in
      next({name: 'home', query: { ref: 'guard_guest' }});
    }
  }
});

export default router;

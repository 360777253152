<template>
  <v-container>
    <v-row>
      <v-spacer/>
      <v-col cols="12" sm="10" md="8">
        <v-sheet elevation="5" class="px-4 py-1 bg-transparent-aoa-half">
          <v-skeleton-loader type="paragraph@5" :loading="info_text === null">
            <v-md-preview :text="info_text"/>
          </v-skeleton-loader>
        </v-sheet>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  async mounted() {
    await this.$store.dispatch('game_information/load');
  },

  computed: {
    ...mapGetters({
      info_text: 'game_information/info_text',
    }),
  }
}
</script>

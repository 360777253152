import '@mdi/font/scss/materialdesignicons.scss'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        "cta": "#B43F30",

        "kickstarter": "#05ce78",
        "patreon": "#f96854",
        "aoa": "#151315",
        "discord": "#7289DA",
      },
    },
  }
})

export default vuetify

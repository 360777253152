import ApiClient from "@/helpers/api-client.js";
import api from "@/api";

export default {
  async create(enquiry) {
    let uri = api.content.qualify("enquiries");

    return await ApiClient.postJson(uri, enquiry);
  }
}

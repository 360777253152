import api from "@/api";
import ApiClient from "@/helpers/api-client.js";
import Vue from "vue";

const auth_cookie_name = "aoa_jwt";

export default {
  namespaced: true,

  state: {
    loggingIn: false,
    user: null,
    jwt: null,
  },

  getters: {
    isLoggedIn(state) {
      // in case we are currently logging in, return null since we don't know if we are or if we aren't logged in
      if (state.loggingIn)
        return null;

      // user is logged in if we have a JWT and a user object with information about them
      return state.user !== null && state.jwt !== null;
    },

    user(state) {
      return state.user;
    }
  },

  mutations: {
    toggleLoggingIn(state, loggingIn) {
      if (state.loggingIn !== loggingIn)
        state.loggingIn = loggingIn;
    },

    storeJwt(state, jwt) {
      if (typeof jwt === 'undefined')
        return;

      // store new value in state
      state.jwt = jwt;

      if (jwt) {
        // store JWT in cookie:
        // - SameSite=Lax required: prevents third-party sites to access this cookie
        // - Secure=false required: otherwise we can't read the cookie from JS

        // MAYBE: set expire time and domain
        Vue.$cookies.set(auth_cookie_name, jwt, null, null, null, false, "Lax");

        // store token in ApiClient so further API requests are authenticated (or remove it if the token has been reset)
        ApiClient.setAuthToken(jwt);
      } else {
        // remove cookie with JWT
        Vue.$cookies.remove(auth_cookie_name);

        // reset API Client so it no longer uses the token
        ApiClient.resetAuthToken();
      }
    },

    storeUser(state, user) {
      if (typeof user === 'undefined')
        return;

      // MAYBE: validate user object before storing it
      state.user = user;
    }
  },

  actions: {
    async tryRefreshUser({ state, commit }) {
      commit('toggleLoggingIn', true);

      try {
        // check if JWT is already stored
        if (state.jwt === null) {
          // check if cookie with JWT exists
          if (!Vue.$cookies.isKey(auth_cookie_name)) {
            // no JWT found -> no login possible

            return;
          }

          let jwt = Vue.$cookies.get(auth_cookie_name);

          // store the JWT we got from the cookie
          commit('storeJwt', jwt);
        } else {
          // make sure ApiClient uses the stored JWT before requesting user data
          ApiClient.setAuthToken(state.jwt);
        }

        // request information about user who owns the JWT
        let user = await api.content.users.me();

        // store the users information
        commit('storeUser', user);
      } catch (e) {
        // reset all stored values since login failed
        commit('storeJwt', null);
        commit('storeUser', null);
      } finally {
        commit('toggleLoggingIn', false);
      }
    },

    async login({ commit, state }, { access_token }) {
      commit('toggleLoggingIn', true);

      try {
        // let the backend resolve the access token to a user and a JWT
        let auth_info = await api.content.auth.resolveAccessToken(access_token);

        commit('storeJwt', auth_info.jwt);
        commit('storeUser', auth_info.user);
      } catch (e) {
        console.error(e);
      } finally {
        commit('toggleLoggingIn', false);
      }
    },

    async logout({ commit }) {
      commit('toggleLoggingIn', true);

      try {
        commit('storeJwt', null);
        commit('storeUser', null);
      } catch (e) {
        console.error(e);
      } finally {
        commit('toggleLoggingIn', false);
      }
    }
  }
}

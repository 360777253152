import api from "@/api";

export default {
  namespaced: true,

  state: {
    count: null,
    ranges: [],
    loading: [],
  },

  getters: {
    ranges(state) {
      return state.ranges;
    },

    latest(state) {
      return state.ranges
        .map(r => r.articles)
        .flatMap(v => v)
        .slice(0, 5); // TODO: order by publish date
    },

    count(state) {
      return state.count;
    },

    all(state) {
      return state.ranges
        .map(r => r.articles)
        .flatMap(v => v);
    }
  },

  mutations: {
    addLoadingRange(state, range) {
      state.loading.push(range);
    },

    removeLoadingRange(state, range) {
      let idx = state.loading.findIndex(r => r.start === range.start && r.limit === range.limit);

      state.loading.splice(idx);
    },

    addRangeLoaded(state, loaded) {
      state.ranges.push(loaded);
    },

    storeCount(state, count) {
      state.count = count;
    }
  },

  actions: {
    async loadCount({state, commit}) {
      if (state.count !== null)
        return state.count;

      let count = await api.content.articles.count();

      commit('storeCount', count);

      return state.count;
    },

    async load({state, commit, dispatch}, {start = 0, limit = 10 } = {}) {
      if (state.loading.some(r => r.start === start && r.limit === limit)) {
        await new Promise((r) => setTimeout(r, 5000));

        if (state.loading.some(r => r.start === start && r.limit === limit))
          throw new Error("Range was not loaded in time.")
      }

      try {
        commit('addLoadingRange', {start, limit});

        await dispatch('loadCount');

        for (let i = 0; i < state.ranges.length; i++) {
          let range = state.ranges[i];
          let rangeIncludesStart = range.start <= start && range.start + range.limit > start;
          let rangeIncludesEnd = range.start + range.limit >= start + limit;
          if (!rangeIncludesStart || !rangeIncludesEnd)
            continue;

          return range; // already within loaded ranges
        }

        let response = await api.content.articles.all(limit, start);
        let range = {
          start: response.meta.pagination.start,
          limit: response.meta.pagination.limit,
          articles: response.data.map(o => ({id: o.id, ...o.attributes}))
        };

        commit('addRangeLoaded', range);

        return range;
      } catch (e) {
        console.error(e);
      } finally {
        commit('removeLoadingRange', {start, limit});
      }
    },

    async loadAll({state, dispatch}) {
      await dispatch('loadCount');

      return await dispatch('load', {start: 0, limit: state.count});
    },

    async get({state, dispatch}, {start, limit}) {
      let range = await dispatch('load', {start, limit});

      return range.articles.slice(start - range.start, start + limit - range.start);
    }
  }
}

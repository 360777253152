<template>
  <div class="text-center">
    <p>
      When browsing this website, you agree with our
      <router-link :to="{name: 'privacy'}">Privacy Policy</router-link> and our
      <router-link :to="{name: 'terms'}">Terms of Service</router-link>.
    </p>

    <v-btn @click="accept" color="cta" class="ma-3">Accept</v-btn>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'PrivacyPopupContent',

  methods: {
    ...mapActions({
      setAccepted: 'options/setAcceptedTos'
    }),

    accept() {
      let payload = {accept: true};

      this.setAccepted(payload);

      this.$emit('responded', payload);
    },
  }
}
</script>

<template>
  <v-snackbar v-bind="$attrs" :timeout="timeout">
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="$emit('clicked', $event)">
        {{ actionText }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "action-snackbar",

  props: {
    text: {
      type: String,
    },
    actionText: {
      type: String,
      default: "Close"
    },
    timeout: {
      type: Number,
      default: 5000
    }
  }
}
</script>

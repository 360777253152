import "@/style/app.scss";

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// plugins
import "@/plugins/api.js";
import "@/plugins/content-image.js";
import "@/plugins/v-md-preview.js";
import "@/plugins/vue-cookies.js";
import "@/plugins/vue-filter-number-format.js";
import "@/plugins/vue-moment.js";
import vuetify from "@/plugins/vuetify.js";

Vue.config.productionTip = process.env.NODE_ENV !== 'production'

new Vue({
  router,
  store,
  render: h => h(App),

  vuetify
}).$mount('#app')

<template>
  <v-dialog v-model="shown" max-width="90vw">
    <template v-slot:activator="{ on, attrs }">
      <v-img :contain="contain"
             :aspect-ratio="aspectRatio"
             :eager="eager"
             :max-height="maxHeight"
             class="pointer"
             v-bind="attrs"
             v-on="on"
             :lazy-src="lazySrc"
             :src="previewSrc" />
    </template>

    <v-responsive>
      <v-img @click="shown = false"
             max-height="90vh"
             contain
             :lazy-src="previewSrc"
             :src="src" />

      <slot></slot>
    </v-responsive>
  </v-dialog>
</template>

<script>
export default {
  name: "lightbox",

  props: [
    "lazySrc",
    "previewSrc",
    "src",
    "maxHeight",
    "contain",
    "eager",
    "aspectRatio"
  ],

  data() {
    return {
      shown: false
    }
  }
}
</script>

import api from "@/api";

export default {
  namespaced: true,

  state: {
    loaded: false,
    promotional_video_embed_url: null,
    promotional_text: null,
  },

  getters: {
    promotional_text(state) {
      return state.promotional_text;
    },

    promotional_video_embed_url(state) {
      return state.promotional_video_embed_url;
    }
  },

  mutations: {
    storePromoText(state, payload) {
      state.promotional_text = payload;
    },

    storePromoVideoEmbed(state, payload) {
      state.promotional_video_embed_url = payload;
    },

    setLoaded(state, loaded) {
      state.loaded = loaded;
    }
  },

  actions: {
    async load({ state, commit }) {
      if (state.loaded)
        return;

      try {
        let metadata = await api.content.frontpage();

        commit('storePromoText', metadata.promotional_text);
        commit('storePromoVideoEmbed', metadata.youtube_video);
        commit('setLoaded', true);
      } catch (e) {
        console.error(e);
      }
    }
  }
}

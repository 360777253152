<template>
  <v-card elevation="10">
    <slot name="pre-body"></slot>

    <v-card-title>Login</v-card-title>
    <v-card-text v-if="acceptedTos && respondedTos" class="text-center">
      <p>
        You can log in using one of these providers:
      </p>

      <v-btn v-for="provider in providers" :key="provider.name" :color="provider.color" :href="provider.href" block>
        <v-icon class="mr-2">{{ provider.icon }}</v-icon>
        <span>Log in via {{ provider.name }}</span>
      </v-btn>
    </v-card-text>
    <v-card-text v-else-if="!respondedTos">
      <p>
        You must first accept our Privacy Policy and our Terms of Service:
      </p>

      <privacy-popup-content />
    </v-card-text>
    <v-card-text v-else>
      <p>
        Sorry, you cannot log in until you accept our Privacy Policy and Terms of Service.
      </p>

      <p>
        If you want to update your choice, reload the page or click on the link at the bottom of the page.
      </p>
    </v-card-text>

    <slot></slot>
  </v-card>
</template>

<script>
import PrivacyPopupContent from "@/components/PrivacyPopupContent.vue";
import {mapGetters} from "vuex";

export default {
  name: 'login-form',

  components: {PrivacyPopupContent},

  created() {
    if (this.$route.query.hasOwnProperty('redirect'))
      localStorage.setItem('redirect-after-login', this.$route.query.redirect);
  },

  data() {
    return {
      providers: [
        {
          name: "Discord",
          icon: "mdi-discord",
          color: "discord",
          href: this.$api.content.auth.loginUrl().href
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      acceptedTos: 'options/acceptedTos',
      respondedTos: 'options/respondedTos'
    }),
  }
}
</script>

<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="9" md="7" lg="6" xl="5">
        <v-sheet elevation="5" class="pa-5">
          <h1>Frequently Asked Questions</h1>

          <p>
            Here you can find answers to your questions. If yours is not answered here, you may want to
            <a href="https://discord.age-of-aincrad.com/">join our discord server</a>.
          </p>

          <v-text-field prepend-inner-icon="search" clearable label="Filter" v-model="search"></v-text-field>
        </v-sheet>

        <v-skeleton-loader type="list-item@10" :loading="questions.length === 0">
          <v-expansion-panels multiple popout class="mt-10" v-model="expand_ids" v-if="filteredGeneral.length > 0">
            <v-expansion-panel v-for="faq in filteredGeneral" :key="faq.id" :id="'faq-' + faq.id">
              <v-expansion-panel-header>{{ faq.question }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-md-preview :text="faq.answer" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-alert v-else color="warning">
            No questions/answers match your query.
          </v-alert>
        </v-skeleton-loader>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  created() {
    this.$store.dispatch('faqs/load');
  },

  mounted() {
    if (this.$route.query.hasOwnProperty('id')) {
      // convert ids to int array and subtract 1 to convert to 0-based index
      this.expand_ids = [this.$route.query.id].flat().map(i => parseInt(i) - 1);

      // check if general FAQs have already been loaded and that there is one target to scroll to
      if (this.questions.length > 0 && this.expand_ids.length === 1)
        this.$nextTick(() => this.$vuetify.goTo('#faq-' + this.expand_ids[0]));
    }
  },

  data() {
    return {
      search: null,
      expand_ids: [],
    }
  },

  watch: {
    questions() {
      // can only scroll to a target if there is only one
      if (this.expand_ids.length === 1)
        this.$nextTick(() => this.$vuetify.goTo('#faq-' + this.expand_ids[0]));
    }
  },

  computed: {
    ...mapGetters({
      questions: 'faqs/questions'
    }),

    searchValue() {
      return this.search ? this.search.toLowerCase() : null;
    },

    filteredGeneral() {
      if (!this.searchValue || this.searchValue.length === 0)
        return this.questions;

      return this.questions.filter(faq =>
        faq.question.toLowerCase().includes(this.searchValue) ||
        faq.answer.toLowerCase().includes(this.searchValue) ||
        faq.tags.some(t => t.includes(this.searchValue))
      );
    }
  }
}
</script>

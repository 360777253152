import {BG_TYPE} from "@/store/options.js";
import Search from "@/views/Search.vue";

import Apply from "@/views/services/Apply.vue";
import Contact from "@/views/services/Contact.vue";
import PrivacyPolicy from "@/views/services/PrivacyPolicy.vue";
import Terms from "@/views/services/Terms.vue";
import FAQs from "@/views/FAQs.vue";
import Home from "@/views/Home.vue";
import Press from "@/views/services/Press.vue";
import Information from "@/views/game/Information.vue";
import NotFound from "@/views/NotFound.vue";

const default_routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      authRequired: null,
      background: {
        url: 'armor.webm',
        type: BG_TYPE.webm
      },
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: PrivacyPolicy,
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    }
  },
  {
    path: '/terms-of-service',
    name: 'terms',
    component: Terms,
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    }
  },
  {
    path: '/apply',
    name: 'apply',
    component: Apply,
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQs,
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    }
  },
  {
    path: '/game',
    name: 'game-info',
    component: Information,
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    }
  },
  {
    path: '/press',
    name: 'press',
    component: Press,
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
      noScroll: true,
    }
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      }
    }
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    }
  },
];

const auth_routes = [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login.vue'),
    meta: {
      authRequired: false,
      background: {
        url: 'auth.png',
        type: BG_TYPE.image
      },
    },
  },
  {
    path: '/auth/connect/:provider/redirect',
    name: 'login-callback',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/LoginCallback.vue'),
    meta: {
      authRequired: false,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    },
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Logout.vue'),
    meta: {
      authRequired: true,
      background: {
        url: 'auth.png',
        type: BG_TYPE.image
      },
    },
  },
  {
    path: '/auth/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Account.vue'),
    meta: {
      authRequired: true,
      background: {
        url: 'auth.png',
        type: BG_TYPE.image
      },
    },
  },
];

const news_routes = [
  {
    path: '/news',
    name: 'articles',
    component: () => import(/* webpackChunkName: "news" */ '@/views/Articles.vue'),
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
      noScroll: true,
    }
  },
  {
    path: '/news/:article_id',
    props: true,
    name: 'article-detail',
    component: () => import(/* webpackChunkName: "news" */ '@/views/ArticleDetail.vue'),
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    }
  },
];

const gallery_routes = [
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '@/views/game/Gallery.vue'),
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    },
    children: [
      {
        path: 'concept-art',
        name: 'gallery-concept-art',
        component: () => import(/* webpackChunkName: "gallery" */ '@/views/game/GalleryConceptArt.vue'),
        meta: {
          authRequired: null,
          background: {
            url: 'home.png',
            type: BG_TYPE.image
          },
        }
      },
      {
        path: 'screenshots',
        name: 'gallery-screenshots',
        component: () => import(/* webpackChunkName: "gallery" */ '@/views/game/GalleryScreenshots.vue'),
        meta: {
          authRequired: null,
          background: {
            url: 'home.png',
            type: BG_TYPE.image
          },
        }
      },
      {
        path: 'videos',
        name: 'gallery-videos',
        component: () => import(/* webpackChunkName: "gallery" */ '@/views/game/GalleryVideos.vue'),
        meta: {
          authRequired: null,
          background: {
            url: 'home.png',
            type: BG_TYPE.image
          },
        }
      },
    ]
  },
];

const store_routes = [
  {
    path: '/store',
    name: 'store',
    component: () => import(/* webpackChunkName: "store" */ '@/views/store/Store.vue'),
    meta: {
      authRequired: null,
      background: {
        url: 'home.png',
        type: BG_TYPE.image
      },
    },
    children: [
      {
        path: 'game',
        name: 'store-game',
        component: () => import(/* webpackChunkName: "store" */ '@/views/store/StoreGame.vue'),
        meta: {
          authRequired: null,
          background: {
            url: 'home.png',
            type: BG_TYPE.image
          },
        }
      },
      {
        path: 'merch',
        name: 'store-merch',
        component: () => import(/* webpackChunkName: "store" */ '@/views/store/StoreMerch.vue'),
        meta: {
          authRequired: null,
          background: {
            url: 'home.png',
            type: BG_TYPE.image
          },
        }
      },
      {
        path: 'vip',
        name: 'store-vip',
        component: () => import(/* webpackChunkName: "store" */ '@/views/store/StoreVip.vue'),
        meta: {
          authRequired: null,
          background: {
            url: 'home.png',
            type: BG_TYPE.image
          },
        }
      },
    ]
  }
];

const combined_routes = [
  default_routes,
  auth_routes,
  news_routes
];

if (typeof process.env.VUE_APP_ENABLE_GALLERY != 'undefined' && process.env.VUE_APP_ENABLE_GALLERY === 'true')
  combined_routes.push(gallery_routes);

if (typeof process.env.VUE_APP_ENABLE_STORE != 'undefined' && process.env.VUE_APP_ENABLE_STORE === 'true')
  combined_routes.push(store_routes);

export default combined_routes.flat();

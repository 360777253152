import ApiClient from "@/helpers/api-client.js";
import api from "@/api";

export default {
  async create(application) {
    const uri = api.content.qualify("applications");
    uri.searchParams.append('populate[0]', 'position');
    uri.searchParams.append('populate[1]', 'attached_file');
    return await ApiClient.postJson(uri.href, application);
  },

  async mine() {
    const me = await api.content.users.me();
    if (!Object.hasOwnProperty.call(me.application, 'id')) {
      return null;
    }

    const uri = api.content.qualify(`applications/${me.application.id}`);
    uri.searchParams.append('populate[0]', 'position');
    uri.searchParams.append('populate[1]', 'attached_file');
    return await ApiClient.getJson(uri.href);
  },

  async updateMine(application) {
    const me = await api.content.users.me();
    if (!Object.hasOwnProperty.call(me.application, 'id')) {
      return null;
    }

    const uri = api.content.qualify(`applications/${me.application.id}`);
    uri.searchParams.append('populate[0]', 'position');
    uri.searchParams.append('populate[1]', 'attached_file');

    return await ApiClient.putJson(uri.href, application);
  },

  async positions() {
    let uri = api.content.qualify("positions");
    let response = await ApiClient.getJson(uri.href);

    return response.data.map(e => ({id: e.id, ...e.attributes}));
  }
}

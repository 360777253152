import api from "@/api";

// all available image formats
const formats = ['large', 'medium', 'small', 'thumbnail'];

export function contentImage(image, format, qualify = true, preferOriginal = false, attribute = 'url') {
  if (!image)
    return null;

  if (!Object.hasOwnProperty.call(image, 'data'))
    return null;

  const data = image.data;
  if (!data || !Object.hasOwnProperty.call(data, 'attributes'))
    return null;

  const attrs = data.attributes;
  if (!attrs)
    return null;

  let value;

  // check if multiple formats are available or if a specific format has been requested
  if (!attrs.hasOwnProperty('formats') || typeof format === 'undefined' || format == null) {
    value = attrs[attribute];
  } else {
    let wantedFormatIndex = formats.findIndex(e => e === format);

    // get subset of possible formats, starting with the largest wanted format
    let wantedFormats = formats.slice().splice(wantedFormatIndex);

    // best format from what is available
    let bestFormat = wantedFormats.find(f => attrs.formats.hasOwnProperty(f));

    // if one was found, return it
    // also check if the original is preferred when the wanted format is not available
    if (typeof bestFormat !== 'undefined' && !(bestFormat !== format && preferOriginal))
      value = attrs.formats[bestFormat][attribute];
    else
      // use the original image attribute as fallback
      value = attrs[attribute];
  }

  if (qualify && attribute === 'url')
    value = api.content.qualify(value).href;

  return value;
}

export default {
  required(message = "Value required") {
    return v => !!v || message;
  },

  min(min = 2, message = `Must be a least ${min} characters`) {
    return v => (!!v && v.length >= min) || message;
  },

  max(max = 1000, message = `Must be at most ${max} characters`) {
    return v => (!!v && v.length <= max) || message;
  },

  email(message = `E-Mail must be valid`) {
    // regex source: https://regexr.com/2rhq7
    return v => /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(v) || message;
  },
}

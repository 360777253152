<template>
  <v-app id="app">
    <transition name="fade" mode="out-in" duration="500">
      <div class="background-wallpaper" :key="background_url">
        <div v-if="!isNone && isImage" class="background-image" :style="'background-image: url(' + background_url + ')'"></div>
        <video v-else-if="!isNone && (isVideo || isWebm)" class="background-video"
               autoplay loop muted preload="auto">
          <source :src="background_url">
        </video>
      </div>
    </transition>

    <navbar :nav="nav" />

    <v-main style="min-height: 100vh">
      <v-container fluid>
        <v-row justify="center" style="min-height: 350px; height: 30vh">
          <v-col align-self="end">
            <v-img max-height="150" contain src="/assets/logos/text/logo_white.png" />

            <p class="text-center uppercase sans-pro semi-bold fpx-20 mt-5">Anime-inspired MMOARPG</p>
          </v-col>
        </v-row>

        <v-row class="spacer-50">
          <v-col>
            <transition name="fade" mode="out-in" duration="500" @after-leave="routeTransitionComplete">
              <router-view />
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <action-snackbar :text="snackbar.text"
                     :action-text="snackbar.action.text"
                     v-model="snackbar.shown"
                     :timeout="snackbar.timeout"
                     @clicked="invokeSnackbarAction" />

    <app-footer />
  </v-app>
</template>

<script>
import ActionSnackbar from "@/components/ActionSnackbar.vue";
import Navbar from "@/components/Navbar.vue";
import store from "@/store";
import AppFooter from "@/components/AppFooter.vue";
import {mapGetters} from "vuex";

export default {
  components: {AppFooter, Navbar, ActionSnackbar},

  mounted() {
    this.showSnackbar();
  },

  data() {
    return {
      background_updated: false,

      snackbar: {
        text: '',
        shown: false,
        timeout: 5000,
        action: {
          text: 'close',
          cb: () => this.snackbar.shown = false
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      loggedIn: 'auth/isLoggedIn',
      user: 'auth/user',
      background_url: 'options/background_url',
      isImage: 'options/isImage',
      isVideo: 'options/isVideo',
      isWebm: 'options/isWebm',
      isNone: 'options/isNone',
    }),

    nav() {
      const default_links = [
        {
          icon: 'home',
          label: 'Home',
          to: 'home'
        },
      ];

      const services_links = [
        {
          icon: 'help_center',
          label: 'FAQ',
          to: 'faq'
        },
      ];

      const support_links = [
        {
          icon: 'info',
          label: 'Support',
          children: [
            {
              label: 'CONTACT US',
              to: 'contact'
            },
            {
              label: 'PRESSKIT',
              to: 'press'
            },
          ],
        }
      ];

      const game_gallery_links = [
        {
          label: 'Game',
          icon: 'mdi-aoa',
          children: [
            {
              label: 'Information',
              to: 'game-info'
            },
            {
              label: 'Concept Art',
              to: 'gallery-concept-art'
            },
            {
              label: 'Screenshots',
              to: 'gallery-screenshots'
            },
            {
              label: 'Videos',
              to: 'gallery-videos'
            },
          ]
        },
      ];

      const game_no_gallery_links = [
        {
          label: 'Game',
          to: 'game-info',
          icon: 'mdi-aoa'
        }
      ];

      const news_links = [
        {
          icon: 'mdi-chart-timeline',
          label: 'Roadmap',
          href: 'https://app.loopedin.io/age-of-aincrad-roadmap',
          target: '_blank'
        },
        {
          icon: 'article',
          label: 'News',
          to: 'articles'
        },
      ];

      const store_links = [
        {
          icon: 'store',
          label: 'Store',
          to: 'store',
          children: [
            {
              label: 'Game Store',
              to: 'store-game'
            },
            {
              label: 'Merchandise',
              to: 'store-merch'
            },
            {
              label: 'VIP Store',
              to: 'store-vip'
            }
          ]
        },
      ];

      const social_links = [
        {
          icon: 'link',
          label: 'Social',
          children: [
            {
              label: "Facebook",
              icon: "mdi-facebook",
              force_icon: true,
              href: "https://www.facebook.com/AgeofAincrad",
              target: '_blank'
            },
            {
              label: "Twitter",
              icon: "mdi-twitter",
              force_icon: true,
              href: "https://twitter.com/AoA_MMORPG",
              target: '_blank'
            },
            {
              label: "YouTube",
              icon: "mdi-youtube",
              force_icon: true,
              href: "https://www.youtube.com/channel/UCNdVwmZdvvGZ83YrkO_qZ-w",
              target: '_blank'
            },
            {
              label: "Discord",
              icon: "mdi-discord",
              force_icon: true,
              href: "https://discord.age-of-aincrad.com/",
              target: '_blank'
            },
          ]
        },
        {
          label: 'JOIN THE TEAM',
          to: 'apply'
        },
      ];

      const combined_links = [
        default_links,
        news_links,
        services_links,
        support_links,
        social_links
      ];

      if (process.env.VUE_APP_ENABLE_GALLERY === 'true')
        combined_links.splice(1, 0, game_gallery_links);
      else
        combined_links.splice(1, 0, game_no_gallery_links);

      if (process.env.VUE_APP_ENABLE_STORE === 'true')
        combined_links.splice(2, 0, store_links);

      return combined_links.flat();
    }
  },

  watch: {
    '$route.query.ref': function () {
      this.showSnackbar();
    },

    '$route.meta': function () {
      if (!this.background_updated)
        this.updateBackground();
    }
  },

  methods: {
    showSnackbar() {
      if (!this.$route.query.hasOwnProperty('ref'))
        return;

      this.snackbar.action.text = "Close";
      this.snackbar.action.cb = () => {};

      switch (this.$route.query.ref) {
        case 'login':
          if (!this.loggedIn)
            return;

          this.snackbar.text = `Welcome back, ${this.user.username}!`;
          break;
        case 'failed_login':
          this.snackbar.text = "Login failed! Please try again.";
          this.snackbar.action.text = "Login";
          this.snackbar.action.cb = () => this.$router.push({name: 'login'});
          break;
        case 'logout':
          this.snackbar.text = "See you soon!";
          break;
        case 'guard_guest':
          this.snackbar.text = "This page is only accessible for guests!";
          break;
        case 'guard_login':
          this.snackbar.text = "This page is only accessible for authenticated users!";
          this.snackbar.action.text = "Login";
          this.snackbar.action.cb = () => this.$router.push({name: 'login'});
          break;
        case 'contact':
          this.snackbar.text = "Thanks for contacting us!";
          break;
        default:
          return;
      }

      // create new query object
      let q = Object.assign({}, this.$route.query);

      // remove 'ref' field
      delete q.ref;

      // replace current query with query without 'ref' field
      this.$router.replace({ query: q }).catch();

      this.snackbar.shown = true;
    },

    invokeSnackbarAction() {
      this.snackbar.shown = false;

      this.snackbar.action.cb();
    },

    async routeTransitionComplete() {
      await this.updateBackground();
    },

    async updateBackground() {
      if (!this.$route.meta.hasOwnProperty('background'))
        return;

      await store.dispatch('options/updateBackground', {
        url: `/assets/backgrounds/${this.$route.meta.background.url}`,
        type: this.$route.meta.background.type
      });

      this.background_updated = true;
    }
  }
}
</script>

<style lang="scss">
#app {
  background-color: #151315;
}

.background-wallpaper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 1s ease;

  &> * {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    $start-transition: 50vh;
    $end-transition: 100vh;
    mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.8) $start-transition,
        rgba(0, 0, 0, 0) $end-transition,
        rgba(0, 0, 0, 0) 100%
    );
  }
}

.background-image {
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-size: contain;
}

.background-video {
  width: 100%;
  height: auto;
}
</style>

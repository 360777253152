import ApiClient from "@/helpers/api-client.js";
import api from "@/api";

export default {
  async all() {
    let url = api.content.qualify('gallery');

    url.searchParams.append('populate[0]', 'components');
    url.searchParams.append('populate[1]', 'components.image');
    url.searchParams.append('populate[2]', 'components.video');

    return await ApiClient.getJson(url.href);
  }
}

import api from "@/api";
import ApiClient from "@/helpers/api-client.js";

export default {
  async all() {
    const url = api.content.qualify('faq?populate=questions')
    const response = await ApiClient.getJson(url.href);

    return response.data.attributes;
  }
}

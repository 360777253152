import api from "@/api";
import ApiClient from "@/helpers/api-client.js";

export default {
  loginUrl() {
    return api.content.qualify("connect/discord");
  },

  async resolveAccessToken(access_token) {
    let callback_url = api.content.qualify("auth/discord/callback");
    callback_url.searchParams.append("access_token", access_token);

    return await ApiClient.getJson(callback_url);
  },
}

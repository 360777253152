import ApiClient from "@/helpers/api-client.js";
import api from "@/api";

export default {
  async all(limit = 10, start = 0) {
    // get fully qualified content url
    let url = api.content.qualify('articles');

    // sort by publish date, newer first
    url.searchParams.append('sort', "publishedAt:desc");

    // limit number of articles and skip number of articles
    url.searchParams.append('pagination[limit]', limit.toString());
    url.searchParams.append('pagination[start]', start.toString());

    // populate fields of articles
    url.searchParams.append('populate[0]', "cover_image");
    url.searchParams.append('populate[1]', "attached_files");
    url.searchParams.append('populate[2]', "categories");
    url.searchParams.append('populate[3]', "createdBy");

    return await ApiClient.getJson(url.href);
  },

  async count() {
    const url = api.content.qualify('articles');

    // will return one article anyway, that's still faster than 25 at least...
    url.searchParams.append('pagination[limit]', "0");

    const response = await ApiClient.getJson(url.href);

    return response.meta.pagination.total;
  }
}
